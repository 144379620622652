//Background Color
$BGColor: white;

.DataStructureVisualizer {
  text-align: center;
}

//Styling top menu
.DataStructureVisualizer body,
.DataStructureVisualizer html {
  background-color: $BGColor;
}

// .DataStructureVisualizer div,
.DataStructureVisualizer p {
  color: #babecc;
}

.DataStructureVisualizer p {
  margin: 0;
}

.heading {
  background-color: $BGColor;
  left: 250px;
  text-align: center;
  height: 55px;
  display: absolute;
  padding: 0px;
}

button,
.DataStructureVisualizer input {
  border: 0;
  outline: 0;
  font-size: 14px;
  border-radius: 50px;
  padding: 16px;
  background-color: $BGColor;
  // text-shadow: 1px 1px 0 #fff;
  margin: 5px;
}
.DataStructureVisualizer .i {
  position: absolute;
  padding: 0px;
  margin-left: 10px;
}

.main-menu-button {
  display: block;
  clear: both;
}

@media screen and (min-width: 550px) {
  .main-menu-button {
    position: absolute;
    right: 10px;
    top: 130px;
  }
}

.menu ul {
  position: inline-block;
}

@media screen and (max-width: 550px) {
  .main-menu-button {
    width: 50%;
    margin: 0 auto;
  }
  div.menu {
    margin-top: 100px;
  }
}

div.menu.trie ul {
  margin-bottom: 0px;
}

div.menu li {
  display: inline-block;
}

.DataStructureVisualizer input {
  box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 5px #fff;
  width: 110px;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  appearance: none;
  -webkit-appearance: none;
}
input:focus {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.DataStructureVisualizer button {
  box-shadow: -2px -2px 2px #fff, 2px 2px 2px #babecc;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-weight: 400;
}

.DataStructureVisualizer button:hover {
  box-shadow: -2px -2px 2px #fff, 2px 3px 3px #babecc;
  color: #3975e4;
  text-shadow: 0px -1px 1px rgba(0, 132, 255, 0.2);
}

button:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.dropdown {
  position: relative;
  display: inline-block;
  transition: all 2s ease-in-out;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: $BGColor;
  border-radius: 20px;
  width: 120%;
  height: 200px;
  padding: 0 10px;
  text-align: center;
  box-shadow: -5px -5px 5px #fff, 5px 5px 5px #babecc;
  z-index: 1;
}
.dropdown-content button {
  position: relative;
  right: 35%;
}

.dropdown:hover .dropdown-content {
  display: block;
  animation: fade_in_show 0.5s;
}

@keyframes fade_in_show {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.traversal,
.bstlist {
  margin: 10px 10px;
  box-shadow: inset 2px 2px 5px #babecc, inset -5px -5px 5px #fff;
  padding: 16px;
  border-radius: 20px;
}

.traversal ul,
.bstlist ul {
  padding: 0;
  margin: 0;
  list-style: none;
  color: #61677c;
  font-weight: bold;
}

.traversal li {
  display: inline;
  padding: 3px;
}

.bstlist li {
  padding: 3px;
}

.error {
  color: red;
}

@media screen and (min-width: 850px) {
  .traversal {
    position: absolute;
    left: 10px;
    top: 130px;
  }
  .traversal.ar {
    position: absolute;
    left: 10px;
    top: 300px;
  }

  .bstlist {
    position: absolute;
    right: 10px;
    top: 220px;
  }
}
