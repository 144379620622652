.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;

  & h1 {
    text-shadow: none;
    // color: rgba(0, 0, 0, 0.788);
    color: #bebecc;
  }

  & .icon {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    color: rgb(59, 59, 59);
    transition: all 0.2s ease-in-out;
    &:hover {
      color: rgb(192, 30, 30);
      text-shadow: 0px -1px 5px rgb(192, 30, 30);
    }
  }
}
.popup .inner {
  position: absolute;
  // position: relative;
  left: 20%;
  right: 20%;
  top: 10%;
  bottom: 10%;
  margin: auto;
  border-radius: 20px;

  //For Firefox
  background-color: #28282a;

  //For other browsers with backdrop-filter support
  @supports (
    (-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))
  ) {
    background-color: #282c34;
    backdrop-filter: blur(0.2rem);
  }

  border: 1px solid rgba(#fff, 0.2);
  box-shadow: -0.25rem -0.25rem 0.5rem rgba(#fff, 0.07),
    0.25rem 0.25rem 0.5rem rgba(#000, 0.12),
    -0.75rem -0.75rem 1.75rem rgba(#fff, 0.07),
    0.75rem 0.75rem 1.75rem rgba(#000, 0.12),
    inset 8rem 8rem 8rem rgba(#000, 0.05),
    inset -8rem -8rem 8rem rgba(#fff, 0.05);

  & div {
    text-shadow: none;
    color: white;
  }

  & > button {
    background-color: rgba(104, 104, 104, 0.39);
    backdrop-filter: blur(0.2rem);
  }
}

.popup.main .inner {
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
}

@media screen and (max-width: 800px) {
  .popup .inner,
  .popup.main .inner {
    position: absolute;
    left: 0;
    right: 0%;
    top: 0;
    bottom: 0%;
    margin: 0;
    border-radius: 0px;
  }

  .popup .icon,
  .popup.main .icon {
    position: absolute;
    right: 2%;
    top: 2%;
    cursor: pointer;
  }
}

.help-list {
  text-align: left;
  list-style: none;
  text-shadow: 0px -1px 1px rgba(180, 180, 180, 0.2);

  & li {
    font-weight: bold;
    text-shadow: none;
  }
}

.inner button {
  margin: 5px 5px;
  font-size: 15px;
  padding: 10px;
  box-shadow: none;
  cursor: default;

  &:hover {
    color: #ff3366;
  }
}

button.link-button {
  padding: 15px;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: -2px -2px 8px rgb(233, 233, 233), 5px 5px 8px #363636;
  color: white;
  text-shadow: none;

  &:hover {
    box-shadow: -2px -2px 5px rgb(233, 233, 233), 2px 2px 5px #363636;
    cursor: pointer;
  }

  &:active {
    box-shadow: inset 1px 1px 2px #000000,
      inset -1px -1px 2px rgb(192, 192, 192);
  }
}
