@mixin buttonColorGlow($c1, $c2) {
  color: $c1;
}

.main-menu {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.actions {
  display: flex;
  .learn {
    text-decoration: none;
    margin: auto;
  }
}

.row{
  display: flex;
  flex-direction: row;
  flex-wrap:wrap
}
